.complete {
  height: 100vh;
  height: 100dvh;
  position: relative;
  overflow: hidden;
  //background-color: #000;
  &--video {
    height: calc(100vh + 1px);
    height: calc(100dvh + 1px);
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
  }
  &--back_link {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 94px);
    opacity: 0;
    transition: 0.2s;
    &-show {
      opacity: 1;
    }
  }
  &--back_link_img {

  }
}