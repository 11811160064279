@function z($name, $childname: 0) {
  $getkey: map-get($z-map, $name);
  $counter: 1;
  $is-index: 0;
  @if $childname != 0 {
    @return index($getkey, $childname);
  }

  @each $map-key, $map-value in $z-map {
    @if $map-key == $name {
      $is-index: $counter;
    }
    $counter: $counter + 1;
  }

  @return $is-index;
}
$z-map: (
        main--wood_img: true,
        main--title_img: true,
        main--lottie_rabbit: true,
        main--button: true,
        main--moti: true
);
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.main {
  position: relative;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-image: radial-gradient(100% 110% at center top, #0f5d73 0%, #000 100%);
  overflow: hidden;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  &--logo_link {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  &--logo_link_img {
  }
  &--lottie_rabbit {
    z-index: z(main--lottie_rabbit);
    position: absolute;
    pointer-events: none;
    width: 275px;
    bottom: 160px;
    &-left {
      left: calc(50% - 187.5px - 58px);
    }
    &-right {
      transform: scale(-1, 1);
      right: calc(50% - 187.5px - 58px);
    }
    > svg {
      pointer-events: none;
    }
  }
  &--wood_img {
    z-index: z(main--wood_img);
    position: absolute;
    left: calc(50% - 194px);
    bottom: 0;
  }
  &--moti {
    pointer-events: none;
    z-index: z(main--moti);
    position: absolute;
    width: 1000px;
    left: calc(50% - 500px + 24px);
    bottom: -20px;
  }
  &--button {
    z-index: z(main--button);
    position: absolute;
    background-image: image-set(url("images/main--button-bgimg.png") 1x, url("images/main--button-bgimg@2x.png") 2x);
    width: 123px;
    height: 123px;
    color: transparent;
    background-color: transparent;
    border: none;
    bottom: 0;
    &-left {
      left: 25px;
    }
    &-right {
      right: 25px;
    }
  }
  &--title_img {
    z-index: z(main--title_img);
    position: absolute;
    top: 11.994003lvh;
    left: calc(50% - 101px);
  }
}
