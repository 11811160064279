.index {
  position: relative;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-image: radial-gradient(100% 110% at center top, #0f5d73 0%, #000 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  &--logo_link {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  &--app_version {
    color: #fff;
    font-size: 10px;
    position: absolute;
    left: 1.5em;
    bottom: 1.5em;
  }
}